// import Vue from 'vue';
import vuetify from '@/plugins/vuetify'

// const theme = Vuetify();
// const selectedTheme = Vue(myThemes[0]);

function init(){
    let themes = vuetify.framework?.theme?.themes;
    if(themes.dark){
        themes.dark.commentaryMaterialityActual = "#B5C99A";
        themes.dark.commentaryMaterialityForecast = "#DFA878";
        themes.dark.primaryicon = "#B5C99A";
        themes.dark.secondaryicon = "#DFA878"
    }
    if(themes.light){
        themes.light.commentaryMaterialityActual = "#B5C99A";
        themes.light.commentaryMaterialityForecast = "#DFA878";
        themes.light.primaryicon = "#B5C99A";
        themes.light.secondaryicon = "#DFA878"
    }
    // console.log("theme");
}

module.exports = init();